<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100">
          <form @submit.prevent="submit">
            <h1>{{ $t("Register your phone number") }}</h1>
            <p class="fw-medium text-gray">
              {{
                $t(
                  "To keep your data save we ask you to verify your phonenumber. We will send a verification code by text message shortly"
                )
              }}
            </p>
            <VueTelInput
              ref="phoneInput"
              v-model="phone_number"
              @input="numberUpdated"
              autofocus
              defaultCountry="nl"
            ></VueTelInput>

            <p class="text-danger fw-bold text-capitalize mt-1" v-if="getErrors.tenant">
              {{ getErrors.tenant }}
            </p>

            <div class="w-100 mt-4">
              <button
                class="btn btn-primary btn-block"
                :disabled="loading"
                :class="{ disabled: !form.phone_number }"
                @click="submit"
              >
                {{ $t("Send verification code") }}
                <b-spinner v-if="loading" small label="Spinning" variant="white">
                </b-spinner>
              </button>
            </div>
          </form>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <RightPoints active="1" />
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import RightPoints from "./RightPoints.vue";

export default {
  data() {
    return {
      step: null,
      form: {
        phone_number: "",
      },
      phone_number: "",
      submitting: false,
      loading: false,
    };
  },
  components: {
    AuthLayoutContent,
    VueTelInput,
    RightPoints,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  async created() {
    this.step = +this.$route.params.step;
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      this.tenant = await this.getTenant(tenant_id);
      this.form.tenant_id = this.tenant.id;
      this.form.phone_number = this.tenant.phone_number;
    }
  },
  mounted() {
    this.$refs.phoneInput.$refs.input.focus();
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant", "sendOTP"]),
    async submit() {
      if (!this.form.phone_number) {
        return;
      }

      this.loading = true;
      await this.updateTenant(this.form);
      await this.sendOTP();
      this.loading = false;
      this.$router.push({
        name: "ExpatStep2",
        params: { tenant_id: this.tenant.id },
      });
    },
    numberUpdated(event, object) {
      // console.log("object", object);
      this.form.phone_number = object.number;
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.vue-tel-input {
  padding: 5px;
}
</style>
